import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { IAuthToken, IWatchList } from "./interfaces";
import Auth from "./components/Auth";
import { getStorage } from "./authService";
import LoggedInUser from "./components/LoggedInUser";

export default function Home() {
  const [baseUrl, setBaseUrl] = useState<string>(
    process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "/api"
  );
  const [auth, setAuth] = useState<IAuthToken | undefined>(undefined);

  const renderMainContent = () => {
    if (auth !== undefined) return <LoggedInUser baseUrl={baseUrl} auth={auth} handleStorage={handleStorage} />;
    return <Auth baseUrl={baseUrl} setAuth={setAuth} />; // Pass setAuth as a prop
  };

  const handleStorage = async () => {
    try {
      const cookie = await getStorage();
      setAuth(cookie);
    } catch (error) {
      console.error("Error fetching cookie: ", error);
    }
  };

  useEffect(() => {
    if (process.env.REACT_APP_BASE_URL) {
      setBaseUrl(process.env.REACT_APP_BASE_URL);
    }
    handleStorage();
  }, []);

  return (
    <Container className="w-100 justify-content-center main-container pt-3">
      {renderMainContent()}
    </Container>
  );
}
