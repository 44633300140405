import { jwtDecode, JwtPayload } from "jwt-decode";
import { IAuthToken } from "./interfaces";

const BASE_URL = process.env.REACT_APP_BASE_URL
  ? process.env.REACT_APP_BASE_URL
  : "/api";

export const authenticateUser = async (
  username: string,
  password: string,
  code: number | undefined
) => {
  try {
    const response = await fetch(`${BASE_URL}/auth/token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username,
        password,
        code,
      }),
    });
    const data = await response.json();
    if (!response.ok) {
      if (data.detail) throw new Error(data.detail);
      else throw new Error("Authentication Failed");
    }

    createOrUpdateStorage(data);
    return data;
  } catch (error) {
    console.error("Authentication error:", error);
    throw error;
  }
};

export const decodeJWT = (token: string): JwtPayload | null => {
  try {
    const decodedToken = jwtDecode<JwtPayload>(token);
    return decodedToken;
  } catch (error) {
    console.error("Failed to decode JWT:", error);
    return null;
  }
};

export const getStorage = async (): Promise<IAuthToken | undefined> => {
  const session = localStorage.getItem("auth");
  let auth: any = null;
  if (session !== null) {
    auth = JSON.parse(session);
  }

  if (auth === undefined || !auth.access_token || !auth.refresh_token) {
    return undefined;
  }

  try {
    const decoded: any = jwtDecode(auth.access_token); // Decode the JWT

    if (decoded && decoded.exp) {
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        await refreshToken(auth); // Await the refresh token call
      }
    }
  } catch (error) {
    console.error("Error decoding JWT:", error);
    return undefined;
  }

  return auth;
};

export const clearStorage = async (): Promise<undefined> => {
  localStorage.removeItem("auth");
  return undefined;
};

export const createOrUpdateStorage = async (token: IAuthToken) => {
  localStorage.setItem("auth", JSON.stringify(token));
};

export const refreshToken = async (token: IAuthToken) => {
  try {
    const response = await fetch(`${BASE_URL}/auth/token/refresh`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(token),
    });

    if (!response.ok) {
      console.error("Failed to Refresh Token", response);
      await clearStorage();
      return;
    }

    const newToken: IAuthToken = await response.json();

    // Handle the new token (e.g., save it in local storage)
    createOrUpdateStorage(newToken);

    // Optionally, you might want to update your app state here
  } catch (error) {
    console.error("Failed to refresh token:", error);
  }
};
