import { IAuthToken, IShowDetail, IWatchList } from "../interfaces";
import * as React from "react";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  ProgressBar,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import {
  Archive,
  BoxSeam,
  BoxSeamFill,
  List,
  Pencil,
  PlusCircle,
  XCircle,
} from "react-bootstrap-icons";
import { getStorage } from "../authService";

export interface IWatchListSelector {
  watchLists: IWatchList[];
  baseUrl: string;
  setCurrentView: (text: string, watchList?: IWatchList) => void;
  fetchWatchLists: () => void;
  loaded: boolean;
  auth: IAuthToken;
}

const WatchListSelector = (props: IWatchListSelector) => {
  const [showModal, setShowModal] = React.useState(false);
  const [modalMode, setModalMode] = React.useState<IWatchList | null>();
  const [newName, setNewName] = React.useState<string>();
  const [archiving, setArchiving] = React.useState<boolean>(false);

  const handleOpen = (mode: IWatchList | null) => {
    setModalMode(mode);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const createNewWatchList = async (name: string) => {
    try {
      const response = await fetch(props.baseUrl + `/watchlist/${name}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.auth.access_token}`,
        },
        method: "POST",
      });
      if (!response.ok) {
        console.error("Failed to fetch data", response);
        return;
      }
    } catch (error) {
      console.error(error);
    } finally {
      props.fetchWatchLists();
    }
  };

  const renameWatchList = async (id: number, name: string) => {
    try {
      const response = await fetch(
        props.baseUrl + `/watchlist/${id}/rename/${name}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${props.auth.access_token}`,
          },
          method: "POST",
        }
      );
      if (!response.ok) {
        console.error("Failed to fetch data", response);
        return;
      }
    } catch (error) {
      console.error(error);
    } finally {
      props.fetchWatchLists();
    }
  };

  const deleteWatchList = async (id: number) => {
    try {
      const response = await fetch(props.baseUrl + `/watchlist/${id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.auth.access_token}`,
        },
        method: "DELETE",
      });
      if (!response.ok) {
        console.error("Failed to fetch data", response);
        return;
      }
    } catch (error) {
      console.error(error);
    } finally {
      props.fetchWatchLists();
    }
  };

  const archiveWatchList = async (id: number) => {
    try {
      const response = await fetch(props.baseUrl + `/watchlist/${id}/archive`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.auth.access_token}`,
        },
        method: "PATCH",
      });
      if (!response.ok) {
        console.error("Failed to fetch data", response);
        return;
      }
    } catch (error) {
      console.error(error);
    } finally {
      props.fetchWatchLists();
    }
  };

  const unarchiveWatchList = async (id: number) => {
    try {
      const response = await fetch(
        props.baseUrl + `/watchlist/${id}/unarchive`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${props.auth.access_token}`,
          },
          method: "PATCH",
        }
      );
      if (!response.ok) {
        console.error("Failed to fetch data", response);
        return;
      }
    } catch (error) {
      console.error(error);
    } finally {
      props.fetchWatchLists();
    }
  };

  const syncProgress = async () => {
    try {
      const response = await fetch(props.baseUrl + `/watchlists/sync`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.auth.access_token}`,
        },
        method: "PATCH",
      });
      if (!response.ok) {
        console.error("Failed to fetch data", response);
        return;
      }
    } catch (error) {
      console.error(error);
    } finally {
      props.fetchWatchLists();
    }
  };

  const calculateProgress = (items: IShowDetail[]): number => {
    let initTotalEpisodes: number = 0;
    let currTotalEpisodes: number = 0;

    items.forEach((i: IShowDetail) => {
      if (i.initEpisodesLeftToWatch !== null && i.currEpisodesLeftToWatch !== null) {
        initTotalEpisodes += i.initEpisodesLeftToWatch;
        currTotalEpisodes += i.currEpisodesLeftToWatch;
      }
    });
    console.log("Init Total", initTotalEpisodes)
    console.log("Curr Total", currTotalEpisodes)
    const progressPercentage =
      currTotalEpisodes > 0
        ? ((initTotalEpisodes - currTotalEpisodes) / initTotalEpisodes) * 100
        : 0;
    return progressPercentage;
  };

  const calculateHoursRemaining = (items: IShowDetail[]): number => {
    let currTotalDuration: number = 0;
    items.forEach((i: IShowDetail) => {
      if (i.currDurationLeftToWatch) {
        currTotalDuration += i.currDurationLeftToWatch;
      }
    });

    return Math.round((currTotalDuration / 3.6e6 + Number.EPSILON) * 100) / 100;
  };

  const calculateEpisodesRemaining = (items: IShowDetail[]): number => {
    let currTotalEpisodes: number = 0;
    items.forEach((i: IShowDetail) => {
      if (i.currEpisodesLeftToWatch) {
        currTotalEpisodes += i.currEpisodesLeftToWatch;
      }
    });
    return currTotalEpisodes;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (modalMode === null && newName !== undefined) {
      await createNewWatchList(newName);
    } else if (modalMode && newName) {
      await renameWatchList(modalMode.id, newName);
    }
    setNewName(undefined);
    handleClose();
  };

  return (
    <>
      <Row className="w-100">
        <h1>Watch Lists:</h1>
        {props.watchLists.length > 0 ? (
          props.watchLists.map((list) => (
            <Col key={list.id} xs={12} sm={6} md={4} lg={3} className="mb-3">
              <Card key={list.name}>
                <Card.Header>{list.name}</Card.Header>
                <Card.Body>
                  <Card.Title>
                    {list.items !== null
                      ? `Contains ${list.items?.length} Show${
                          list.items.length > 0 ? "s" : ""
                        }`
                      : "No Shows Added"}
                  </Card.Title>
                  {list.items && (
                    <p>Hours Left: {calculateHoursRemaining(list.items)}</p>
                  )}
                  {list.items && (
                    <p>
                      Episodes Left: {calculateEpisodesRemaining(list.items)}
                    </p>
                  )}
                  <ButtonGroup className="d-flex justify-content-center align-items-center mb-3">
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={<Tooltip id="button-tooltip">Rename</Tooltip>}
                    >
                      <Button
                        variant="warning"
                        onClick={() => handleOpen(list)}
                      >
                        <Pencil />
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={<Tooltip id="button-tooltip">Remove</Tooltip>}
                    >
                      <Button
                        variant="danger"
                        onClick={() => deleteWatchList(list.id)}
                      >
                        <XCircle />
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={<Tooltip id="button-tooltip">Show List</Tooltip>}
                    >
                      <Button
                        variant="info"
                        onClick={() => props.setCurrentView("", list)}
                        disabled={!props.loaded}
                      >
                        {!props.loaded ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          <List />
                        )}
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip id="button-tooltip">
                          {!list.archived ? "Archive" : "Unarchive"}
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="secondary"
                        onClick={() =>
                          !list.archived
                            ? archiveWatchList(list.id)
                            : unarchiveWatchList(list.id)
                        }
                      >
                        {archiving ? (
                          <Spinner animation="border" size="sm" />
                        ) : !list.archived ? (
                          <BoxSeam />
                        ) : (
                          <BoxSeamFill />
                        )}
                      </Button>
                    </OverlayTrigger>
                  </ButtonGroup>
                  {list.items && (
                    <ProgressBar now={calculateProgress(list.items)} />
                  )}
                </Card.Body>
              </Card>
            </Col>
          ))
        ) : (
          <h1>Loading Lists</h1>
        )}
        <Col key="newCol" xs={12} sm={6} md={4} lg={3} className="mb-3">
          <Card key="newCard">
            <Card.Header>Create New</Card.Header>
            <Card.Body>
              <Card.Title>Click to Create</Card.Title>
              <ButtonGroup className="d-flex justify-content-center align-items-center">
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip id="button-tooltip">Add New</Tooltip>}
                >
                  <Button variant="warning" onClick={() => handleOpen(null)}>
                    <PlusCircle />
                  </Button>
                </OverlayTrigger>
              </ButtonGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Button onClick={syncProgress}>Sync WatchLists</Button>
      <Button onClick={getStorage}>Refresh Token</Button>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {modalMode ? `Rename ${modalMode.name}` : "Enter New Name"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Control
              value={newName}
              onChange={(e: any) => setNewName(e.target.value)}
              placeholder={modalMode ? modalMode.name : "Enter New Name"}
              type="text"
            ></Form.Control>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="success" onClick={handleSubmit}>
            {modalMode ? "Rename" : "Create"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default WatchListSelector;
