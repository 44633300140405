import * as React from "react";
import Row from "react-bootstrap/esm/Row";
import { IAuthToken, IShowDetail, IWatchList } from "../interfaces";
import {
  Col,
  Card,
  Button,
  ButtonGroup,
  OverlayTrigger,
  Tooltip,
  Modal,
  Image,
  Form,
  InputGroup,
  Spinner,
  Container,
  ProgressBar,
} from "react-bootstrap";
import {
  PlusLg,
  XCircle,
  Search,
  List,
  ArrowLeftCircle,
  ArrowUp,
  ArrowDown,
} from "react-bootstrap-icons";

export interface IMediaSelectorProps {
  plexUnwatched: IShowDetail[];
  selectedWatchList: IWatchList;
  baseUrl: string;
  fetchWatchLists: () => void;
  setCurrentView: (text: string, watchList?: IWatchList) => void;
  auth: IAuthToken;
}

const MediaSelector = (props: IMediaSelectorProps) => {
  const [plexUnwatched, setPlexUnwatched] = React.useState<IShowDetail[]>(
    props.plexUnwatched
  );
  const [searchResults, setSearchResults] = React.useState<IShowDetail[]>(
    props.plexUnwatched
  );
  const [showModal, setShowModal] = React.useState(false);
  const [selectedShow, setSelectedShow] = React.useState<IShowDetail>();
  const [loadingDetails, setLoadingDetails] = React.useState<
    Record<string, boolean>
  >({});
  const [loadingAR, setLoadingAR] = React.useState<Record<string, boolean>>({});
  const [showWatchListItems, setShowWatchListItems] = React.useState(false);
  const uniqueGenres = [
    ...new Set(props.plexUnwatched.flatMap((show) => show.genres)),
  ].sort();
  const [searchText, setSearchText] = React.useState<string>("");
  const [selectedGenre, setSelectedGenre] =
    React.useState<string>("Select Genre");

  const [orderChanging, setOrderChanging] = React.useState<Boolean>(false);

  const isShowInWatchlist = (show: IShowDetail) => {
    return (
      props.selectedWatchList.items &&
      props.selectedWatchList.items.some((item) => item.guid === show.guid)
    );
  };

  const getShowFromWatchList = (guid: string) => {
    if (props.selectedWatchList.items) {
      return props.selectedWatchList.items.find((s) => s.guid === guid);
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedShow(undefined);
  };

  const getItemByGuid = (guid: string) => {
    return plexUnwatched.find((show) => show.guid === guid);
  };

  const fetchMoreShowDetails = async (guid: string) => {
    let currentItem: IShowDetail | undefined = getShowFromWatchList(guid);
    if (!currentItem) {
      currentItem = getItemByGuid(guid);
    }
    try {
      if (currentItem && currentItem.currEpisodesLeftToWatch !== undefined) {
        return currentItem;
      }
      const response = await fetch(
        `${props.baseUrl}/plex/unwatched/detail?guid=${guid}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${props.auth.access_token}`,
          },
          method: "GET",
        }
      );
      if (!response.ok) {
        console.error("Failed to fetch data", response);
        return;
      }
      const data: IShowDetail = await response.json();
      setPlexUnwatched((prevItems) =>
        prevItems.map((item) =>
          item.guid === guid ? { ...item, ...data } : item
        )
      );
      return data;
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDetails((prev) => ({ ...prev, [guid]: false }));
    }
  };

  const fetchRemoveFromWatchList = async (id: number) => {
    setLoadingAR((prev) => ({ ...prev, [id]: true }));
    try {
      const response = await fetch(
        `${props.baseUrl}/watchlist/${props.selectedWatchList.id}/show/${id}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${props.auth.access_token}`,
          },
          method: "DELETE",
        }
      );
      if (!response.ok) {
        console.error("Failed to fetch data", response);
        return;
      }
      props.fetchWatchLists();
      if (props.selectedWatchList.items) {
        const updatedItems = props.selectedWatchList.items.filter(
          (item) => item.id !== id
        );
        props.setCurrentView("mediaSelector", {
          ...props.selectedWatchList,
          items: updatedItems,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingAR((prev) => ({ ...prev, [id]: false }));
    }
  };

  const fetchAddToWatchList = async (item: IShowDetail) => {
    setLoadingAR((prev) => ({ ...prev, [item.guid]: true }));
    const showDetails = await fetchMoreShowDetails(item.guid);
    if (showDetails) {
      try {
        const response = await fetch(
          `${props.baseUrl}/watchlist/${props.selectedWatchList.id}/show`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${props.auth.access_token}`,
            },
            method: "POST",
            body: JSON.stringify(showDetails),
          }
        );
        if (!response.ok) {
          console.error("Failed to fetch data", response);
          return;
        }
        props.fetchWatchLists();
        if (props.selectedWatchList.items !== null) {
          const updatedItems = [...props.selectedWatchList.items, showDetails];
          props.setCurrentView("mediaSelector", {
            ...props.selectedWatchList,
            items: updatedItems,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingAR((prev) => ({ ...prev, [item.guid]: false }));
      }
    }
  };

  const fetchSetWatchListOrder = async (order: number[]) => {
    try {
      await fetch(
        `${props.baseUrl}/watchlist/${props.selectedWatchList.id}/show/order`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${props.auth.access_token}`,
          },
          method: "PATCH",
          body: JSON.stringify(order),
        }
      );
    } catch (error) {
      console.error(error);
    } finally {
      props.fetchWatchLists();
    }
  };

  const handleDetailClick = async (guid: string) => {
    setLoadingDetails((prev) => ({ ...prev, [guid]: true }));
    const showDetails = await fetchMoreShowDetails(guid);
    setSelectedShow(showDetails);
    setShowModal(true);
  };

  const handleMove = async (index: number, up: boolean) => {
    if (
      props.selectedWatchList.items &&
      props.selectedWatchList.items.length > 0
    ) {
      setOrderChanging(true);
      const watchlistItems = [...props.selectedWatchList.items];
      const [movedItem] = watchlistItems.splice(index, 1);
      up
        ? watchlistItems.splice(index - 1, 0, movedItem)
        : watchlistItems.splice(index + 1, 0, movedItem);

      const updatedItems = watchlistItems.map((item, i) => ({
        ...item,
        order: i + 1,
      }));

      try {
        await fetchSetWatchListOrder(updatedItems.map((item) => item.id));
        props.fetchWatchLists();
        props.setCurrentView("mediaSelector", {
          ...props.selectedWatchList,
          items: updatedItems,
        });
      } catch (error) {
        console.error(error);
      } finally {
        setOrderChanging(false); // Set to false here
      }
    } else {
      console.log("watchlist is empty");
      return;
    }
  };

  React.useEffect(() => {
    setPlexUnwatched(props.plexUnwatched);
    setSearchResults(props.plexUnwatched);
  }, [props.plexUnwatched]);

  const handleSearchChange = () => {
    const lowercasedSearchText = searchText.toLowerCase();

    let filteredShows = plexUnwatched;

    if (lowercasedSearchText.length > 0) {
      filteredShows = filteredShows.filter((show) =>
        show.title.toLowerCase().includes(lowercasedSearchText)
      );
    }

    if (selectedGenre && selectedGenre !== "Select Genre") {
      filteredShows = filteredShows.filter((show) =>
        show.genres.includes(selectedGenre)
      );
    }

    setSearchResults(filteredShows);
  };

  React.useEffect(() => {
    handleSearchChange();
  }, [searchText, selectedGenre]);

  const LoadingSpinner = () => <Spinner animation="border" size="sm" />;

  return (
    <Container fluid>
      <Row className="w-100">
        <Col xs={12} md={3} className="mb-3">
          <ButtonGroup className="w-100">
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip id="button-tooltip">Go Back</Tooltip>}
            >
              <Button
                variant="info"
                onClick={() => props.setCurrentView("", undefined)}
              >
                <ArrowLeftCircle />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id="button-tooltip">
                  {showWatchListItems ? "Show Watch List" : "Hide Watch List"}
                </Tooltip>
              }
            >
              <Button
                variant={showWatchListItems ? "success" : "danger"}
                onClick={() => setShowWatchListItems(!showWatchListItems)}
              >
                <List />
              </Button>
            </OverlayTrigger>
          </ButtonGroup>
          <div
            hidden={!showWatchListItems}
            style={{
              height: "80vh",
              overflowY: "auto",
              border: "1px solid #ddd",
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            <h2>{props.selectedWatchList.name}</h2>
            <ul className="list-unstyled">
              {props.selectedWatchList.items &&
                props.selectedWatchList.items.map((item, index) => (
                  <li key={item.guid}>
                    <Card className="mb-2" key={index}>
                      <Card.Body>
                        <Row>
                          <Col sm={8}>
                            <div className="d-flex justify-content-between align-items-center">
                              <Card.Title
                                className="mb-0"
                                style={{ fontSize: "100%" }}
                              >
                                {item.title}
                              </Card.Title>
                            </div>
                            <Button
                              variant="danger"
                              className="w-100 mt-2"
                              onClick={() => fetchRemoveFromWatchList(item.id)}
                            >
                              {loadingAR[item.guid] ? (
                                <LoadingSpinner />
                              ) : (
                                <XCircle />
                              )}{" "}
                              Remove
                            </Button>
                          </Col>
                          <Col>
                            <Button
                              variant="outline-primary"
                              size="sm"
                              className="me-2 mb-2"
                              onClick={() => handleMove(index, true)}
                              hidden={index === 0}
                            >
                              {orderChanging ? <LoadingSpinner /> : <ArrowUp />}
                            </Button>
                            <Button
                              variant="outline-primary"
                              size="sm"
                              onClick={() => handleMove(index, false)}
                              hidden={
                                props.selectedWatchList.items !== null &&
                                index ===
                                  props.selectedWatchList.items.length - 1
                              }
                            >
                              {orderChanging ? (
                                <LoadingSpinner />
                              ) : (
                                <ArrowDown />
                              )}
                            </Button>
                          </Col>
                        </Row>
                        {item.initEpisodesLeftToWatch &&
                        item.currEpisodesLeftToWatch ? (
                          <ProgressBar
                            className="mt-3"
                            now={
                              item.initEpisodesLeftToWatch -
                              item.currEpisodesLeftToWatch
                            }
                            max={item.initEpisodesLeftToWatch}
                          />
                        ) : null}
                      </Card.Body>
                    </Card>
                  </li>
                ))}
            </ul>
          </div>
        </Col>
        <Col xs={12} md={9}>
          <h1>Selected Watch List is: {props.selectedWatchList.name}</h1>
          <InputGroup className="mt-5 mr-2 ml-2 mb-3">
            <Form.Control
              key="search-box"
              type="search"
              placeholder="Type to Search"
              value={searchText}
              onChange={(event) => setSearchText(event.target.value)}
            />
            <Form.Select
              key="genre-select"
              value={selectedGenre}
              onChange={(event) => setSelectedGenre(event.target.value)}
            >
              <option>Select Genre</option>
              {uniqueGenres &&
                uniqueGenres.map((genre) => <option>{genre}</option>)}
            </Form.Select>
          </InputGroup>
          <Row>
            {plexUnwatched.length &&
              searchResults.map((show) => (
                <Col
                  key={show.guid}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  className="mb-3"
                >
                  <Card style={{height: "410px"}}>
                    <Card.Img variant="top" src={show.posterUrl}  />
                    <Card.Body className="align-content-end">
                      <Card.Title
                        className="text-center"
                        style={{ fontSize: "100%", overflow: "hidden", whiteSpace: "nowrap" }}
                      >
                          {show.title}
                      </Card.Title>
                      <ButtonGroup className="d-flex justify-content-end align-items-end">
                        {!isShowInWatchlist(show) ? (
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip id="button-tooltip">
                                Add To Selected Watchlist
                              </Tooltip>
                            }
                          >
                            <Button
                              variant="success"
                              onClick={() => fetchAddToWatchList(show)}
                            >
                              {loadingAR[show.guid] ? (
                                <LoadingSpinner />
                              ) : (
                                <PlusLg />
                              )}
                            </Button>
                          </OverlayTrigger>
                        ) : (
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip id="button-tooltip">
                                Remove From Selected Watchlist
                              </Tooltip>
                            }
                          >
                            <Button
                              variant="danger"
                              onClick={() => fetchRemoveFromWatchList(show.id)}
                            >
                              {loadingAR[show.guid] ? (
                                <LoadingSpinner />
                              ) : (
                                <XCircle />
                              )}
                            </Button>
                          </OverlayTrigger>
                        )}
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip">
                              Load Additional Details
                            </Tooltip>
                          }
                        >
                          <Button
                            variant="info"
                            onClick={() => handleDetailClick(show.guid)}
                          >
                            {loadingDetails[show.guid] ? (
                              <LoadingSpinner />
                            ) : (
                              <Search />
                            )}
                          </Button>
                        </OverlayTrigger>
                      </ButtonGroup>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
          </Row>
        </Col>
      </Row>
      {selectedShow && (
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{selectedShow.title}</Modal.Title>
          </Modal.Header>
          <Row>
            <Col xs={6} md={4}>
              <Image src={selectedShow.posterUrl} thumbnail />
            </Col>
            <Col>
              <Row>
                <Modal.Body>{selectedShow.summary}</Modal.Body>
              </Row>
              <Row>
                <Modal.Body>Status: {selectedShow.seriesStatus}</Modal.Body>
              </Row>
              <Row>
                <Modal.Body>
                  Episodes Left to Watch: {selectedShow.currEpisodesLeftToWatch}
                </Modal.Body>
              </Row>
              <Row>
                <Modal.Body>
                  Missing Episodes:{" "}
                  {!selectedShow.seriesIsFullyAvailable ? "Yes" : "No"}
                </Modal.Body>
              </Row>
              {selectedShow.averageEpisodeLength ? (
                <Row>
                  <Modal.Body>
                    Avg Episode Runtime:{" "}
                    {Math.round(
                      (selectedShow.averageEpisodeLength / 60000 +
                        Number.EPSILON) *
                        100
                    ) / 100}{" "}
                    Minutes
                  </Modal.Body>
                </Row>
              ) : (
                <></>
              )}
              {selectedShow.currDurationLeftToWatch ? (
                <Row>
                  <Modal.Body>
                    Remaining Duration:{" "}
                    {Math.round(
                      (selectedShow.currDurationLeftToWatch / 3.6e6 +
                        Number.EPSILON) *
                        100
                    ) / 100}{" "}
                    Hours
                  </Modal.Body>
                </Row>
              ) : (
                <></>
              )}
            </Col>
          </Row>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
};

export default MediaSelector;
