import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { IAuthToken, IShowDetail, IWatchList } from "../interfaces";
import MediaSelector from "./MediaSelector";
import WatchListSelector from "./WatchListSelector";
export interface ILoggedInUser {
  auth: IAuthToken;
  baseUrl: string;
  handleStorage: () => void;
}

export default function LoggedInUser(props: ILoggedInUser) {
  const [plexUnwatched, setPlexUnwatched] = useState<IShowDetail[]>([]);
  const [watchLists, setWatchLists] = useState<IWatchList[]>([]);
  const [currentView, setCurrentView] = useState("home");
  const [selectedWatchList, setSelectedWatchList] =
    useState<IWatchList | null>();
  const [loaded, setLoaded] = useState<boolean>(false);

  const handleNavigation = (data: string, watchList?: IWatchList) => {
    setCurrentView(data);
    setSelectedWatchList(watchList || null);
  };

  const renderMainContent = () => {
    if (selectedWatchList == null) {
      return (
        <WatchListSelector
          auth={props.auth}
          watchLists={watchLists}
          baseUrl={props.baseUrl}
          setCurrentView={handleNavigation}
          fetchWatchLists={fetchWatchLists}
          loaded={loaded}
        />
      );
    }
    if (selectedWatchList !== null) {
      return (
        <MediaSelector
          auth={props.auth}
          plexUnwatched={plexUnwatched}
          selectedWatchList={selectedWatchList}
          baseUrl={props.baseUrl}
          fetchWatchLists={fetchWatchLists}
          setCurrentView={handleNavigation}
        />
      );
    }
  };

  const fetchPlexUnwatched = async () => {
    try {
      const response = await fetch(props.baseUrl + "/plex/unwatched", {
        method: "GET",
        headers: {
          "Content-Type": "application/json", // Adjust the Content-Type if needed
          Authorization: `Bearer ${props.auth.access_token}`, // Add the Authorization header with the token
        },
      });
      if (!response.ok) {
        console.error("Failed to fetch data", response);
        if (response.status === 401) {
          props.handleStorage();
        }
        return;
      }
      const data = await response.json();
      setPlexUnwatched(data); // Save fetched data to state
    } catch (error) {
      console.error(error);
    } finally {
      setLoaded(true);
    }
  };

  function sortWatchLists(watchLists: IWatchList[]): IWatchList[] {
    return watchLists.map((watchList) => ({
      ...watchList,
      items: (watchList.items ?? []).sort(
        (a, b) => (a.order ?? Infinity) - (b.order ?? Infinity)
      ),
    }));
  }

  const fetchWatchLists = async () => {
    try {
      const response = await fetch(props.baseUrl + "/watchlists", {
        method: "GET",
        headers: {
          "Content-Type": "application/json", // Adjust the Content-Type if needed
          Authorization: `Bearer ${props.auth.access_token}`, // Add the Authorization header with the token
        },
      });
      if (!response.ok) {
        console.error("Failed to fetch data", response);
        if (response.status === 401) {
          props.handleStorage();
        }
        return;
      }
      const data = await response.json();
      setWatchLists(sortWatchLists(data)); // Save fetched data to state
    } catch (error) {
      console.error(error);
    } finally {
      if (props.auth !== undefined) {
        fetchPlexUnwatched();
      }
    }
  };

  useEffect(() => {
    fetchWatchLists();
  }, [props.auth]);

  return (
    <Container className="w-100 justify-content-center main-container pt-3">
      {renderMainContent()}
    </Container>
  );
}
